import { Menu } from 'amphitheatre';
import { graphql, Link, navigate, StaticQuery } from 'gatsby';
import React, { useLayoutEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { FiLogIn } from 'react-icons/fi';
import Logo from 'src/images/saberlogo.png';
import styled from 'styled-components';

import { useLocation } from '@reach/router';

interface WrapperProps {
  slide?: string;
}

const MenuWrapper = styled.div<WrapperProps>`
  .navigation-bar {
    background: rgba(18, 17, 16, 1);
    position: fixed;
    top: ${({ slide }) => slide || '0px'};
    transition: all 500ms;
    width: 100vw;
    z-index: 1200;

    .col-2 {
      padding-left: 1rem;
    }

    .desktop-container {
      padding-right: 1rem;
    }
  }
`;

interface Props {
  site: {
    siteMetadata: {
      rawURL: string;
    };
  };
}

function MainMenu({ site }: Props) {
  const { rawURL } = site.siteMetadata;
  const [slide, setSlide] = useState('0px');
  const [lastScroll, setLastScroll] = useState(0.0);

  const location = useLocation();
  const { pathname } = location;

  function onMenuClick(id: string) {
    navigate(`/${id}`);
  }

  useLayoutEffect(() => {
    function changeMenu() {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      const scrolled = Math.max(winScroll / height, 0);

      if (!isNaN(scrolled)) {
        if (scrolled <= lastScroll) {
          setSlide('0px');
        } else {
          setSlide('-78px');
        }
      } else {
        setSlide('0px');
      }

      setLastScroll(scrolled);
    }

    window.addEventListener('scroll', changeMenu);
    return () => window.removeEventListener('scroll', changeMenu);
  }, [lastScroll]);

  // This implementation allows for the main menu to highlight for submenus
  // e.g. /pigi/tech-specs/
  const activeId = pathname.split('/').filter((value) => value)[0] + '/';

  return (
    <MenuWrapper slide={slide}>
      <Menu
        activeId={activeId}
        cover
        imgObj={
          <Link to="/" aria-label="Navigate to index page">
            <img
              src={Logo}
              style={{ height: '40px', padding: '1rem 0' }}
              alt="Saber Astronautics"
            />
          </Link>
        }
        onMenuClick={onMenuClick}
        data-cy="main-menu"
        role="navigation"
      >
        <Menu.Item to="">
          <FaHome />
        </Menu.Item>
        <Menu.Dropdown label="Services">
          <Menu.Item to="service-overview/">Overview</Menu.Item>
          <Menu.Item to="mission-design/">Mission Design</Menu.Item>
          <Menu.Item to="pre-flight/">Pre-flight</Menu.Item>
          <Menu.Item to="diagnostics/">Diagnostics</Menu.Item>
          <Menu.Item to="operations/">Operations</Menu.Item>
        </Menu.Dropdown>
        <Menu.Dropdown label="Products">
          <Menu.Item to="pigi/">P.I.G.I.</Menu.Item>
          <Menu.Item to="space-cockpit/">Space Cockpit</Menu.Item>
          <Menu.Item to="mdat/" style={{ whiteSpace: 'pre' }}>
            Mission Design and <br />
            Analysis Toolkit
          </Menu.Item>
        </Menu.Dropdown>
        <Menu.Dropdown label="Community">
          <Menu.Item to="engagements/">Engagements</Menu.Item>
          <Menu.Item to="events/">Events</Menu.Item>
        </Menu.Dropdown>
        <Menu.Dropdown label="Company">
          <Menu.Item to="company/">About</Menu.Item>
          <Menu.Item to="careers/">Careers</Menu.Item>
          <Menu.Item to="internships/">Internships</Menu.Item>
        </Menu.Dropdown>
        <Menu.Dropdown label="Media">
          <Menu.Item to="press-releases/">Press Releases</Menu.Item>
          <Menu.Item to="news/">News</Menu.Item>
        </Menu.Dropdown>
        <Menu.Divider />
        <Menu.Dropdown label="Support">
          <Menu.Item to="downloads/">Downloads</Menu.Item>
          <Menu.Item to="tutorials/">Tutorials</Menu.Item>
        </Menu.Dropdown>
        <Menu.Item to="contact-us/">Contact Us</Menu.Item>
        <Menu.Item href={`https://account.${rawURL}`}>
          Account
          <FiLogIn style={{ marginLeft: 5 }} />
        </Menu.Item>
      </Menu>
    </MenuWrapper>
  );
}

export default function (): JSX.Element {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              rawURL
            }
          }
        }
      `}
      render={({ site }) => <MainMenu site={site} />}
    />
  );
}
